.MuiDataGrid-columnHeaderTitle {
  color: #3b3c86 !important;
  font-weight: bolder !important;
  opacity: 1;
  letter-spacing: 0px;
  font: Poppins;
}

.MuiDataGrid-columnSeparator {
  background-color: transparent !important;
  color: transparent !important;
}

.header-class {
  padding-left: 10;
}
